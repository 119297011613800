.cima_card_class{

    display: flex;

}

.card_class{

    background-color: #fff;
    min-width: 350px;
    margin-right: 10px;
    border-radius: 10px;
    box-shadow: 0px 3px 4px -5px #000;

}

.card_class_title .dropdown .btn-secondary{

    padding: 0px;
    padding-left: 9px;
    padding-right: 9px;
    box-shadow: 0px 0px 0px 0px;
    position: relative;
    top: -4px;
    margin-right: 3px;
    background-color: white;

}

.icon_add_dd{
  
    position: absolute;
    right: 31px;
    color: #212529;
    top: 1px;
    cursor: pointer;

}

.icon_add_dd:hover{

    transform: translateY(-1px);
    transition: all 0.15s ease;

}

.card_class_title{

    font-size: 15px;
    font-weight: 600;
    padding-left: 10px;
    margin-top: 8px;
    box-shadow: 0px 4px 4px -6px #000;
    position:relative;
    padding-bottom: 5px;

}

.card_class_title .dropdown {

    position: absolute;
    right: 0;

}

.nenhum_chapter{

    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 12px;

}

.react-trello-lane{

    display: flex;
    z-index: 699;

}

.chapter{

    display: flex;
    /*margin-left: 17px;*/
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;

}

.chatp_mover{

    margin-right: 5px;

}

.icon_chapter{

    background-color: royalblue;
    color: #fff;
    border-radius: 50px;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 10px;
    display: flex;
    justify-content:center;
    align-items: center;
    padding-left: 6.5px;
    padding-right: 6.5px;
    
}

.chapter .trash{

    position: absolute;
    right: 20px;

}

.chapter_name{

    margin-left: 7px;
    font-weight: 100;
    font-size: 15px;

}